import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Hero from "./components/hero/Hero";
import Photos from "./components/photos/Photos";
import About from "./components/about/About";
import Schedule from "./components/concerts/Schedule";
import Contact from "./components/contact/Contact";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTicket } from "@fortawesome/free-solid-svg-icons";

library.add(faTicket);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/concerts" element={<Navigate to="/schedule" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
